import React from 'react';
import { db } from '../firebase';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";



function FrontPage({ isLoggedIn }) {

  const [reviews, setReviews] = React.useState([]);

  React.useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = db.collection("publicData").doc("reviews");
        const doc = await reviewsRef.get();
        if (doc.exists) {
          const reviewsData = Object.entries(doc.data()).map(([key, value]) => ({
            id: key,
            text: value
          }));
          setReviews(reviewsData);
        } else {
          console.log("No reviews found");
          setReviews([]);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
  
    fetchReviews();
  }, []);
  



  const handleButtonClick = () => {
    if (isLoggedIn) {
      window.location.href = '/user_profile';
    } else {
      window.location.href = '/login';
    }
  };

  return (
    <div className="bg-gray-200 flex flex-col justify-start pt-10">
      <div className="max-w-md rounded-lg shadow-lg p-8 bg-green mx-auto bg-gray-100">
        <h1 className="text-2xl font-bold mb-4">Bienvenido a AimHarder Bot</h1>
        <p className="text-gray-500 mb-4">
          Cansado de tener que reservar manualmente tus classes?
        </p>
        <p className="text-gray-500 mb-4">
          AimHarderBot es un sistema automatizado de reserva de classes
        </p>
        <p className="text-gray-500 mb-4">
          Selecciona que horario y classes quieres hacer y el bot las reservará para tí!
        </p>
        <div className='flex flex-col items-center'>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleButtonClick}
          >
            Empezar!
          </button>
        </div>
        <p className="text-gray-500 text-sm mt-7">
          Este proyecto se ha hecho como hobbie. Si tienes alguna sugerencia o encuentras algún problema no dudes en contactar a{' '}
          <a href="mailto:info@aimharderbot.com" className="text-blue-500 underline">
            info@aimharderbot.com
          </a>
        </p>
      </div>
      <h2 className="text-xl font-bold mt-10 flex flex-col justify-start text-center">Que dicen los usuarios del proyecto </h2>
      <div className="reviews-carousel w-full max-w-lg mt-2 mx-auto ">
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={5000}
        >
          {reviews.map((review) => (
            <div key={review.id} className="review-card p-4 bg-gray-100 shadow-lg rounded-lg m-6">
              <p className="text-gray-800">{review.text}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default FrontPage;