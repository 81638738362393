import React, { useEffect } from 'react';
import { auth } from '../firebase';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import InitializeFireBase from '../firebase';

function Login() {
  useEffect(() => {
    InitializeFireBase();
  }, []);

  return (
    <div>
      <div id="firebaseui-auth-container"></div>
      <div id="loader">Loading...</div>
    </div>
  );
}

export default Login;
