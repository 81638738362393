import React from 'react';
import BuyMeCoffeeButton from './BuyMeCoffeeButton';

const Footer = () => {
  return (
    <footer style={{
      width: '100%',
      backgroundColor: '#e5e7eb',
      textAlign: 'center',
    }}>
      <BuyMeCoffeeButton />
    </footer>
  );
};

export default Footer;