import FrontPage from './pages/FrontPage';
import NavBar from './components/NavBar';
import UserProfile from './pages/UserProfile';
import Footer from './components/Footer';  // Importa el nuevo componente
import './App.css';
import Login from './pages/login';
import { Routes, BrowserRouter, Route} from 'react-router-dom';

function App({ isLoggedIn }) {
  return (
    <BrowserRouter> 
      <div className="App" style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e5e7eb',
      }}>
        <NavBar isLoggedIn={isLoggedIn}/>
        <main style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<FrontPage isLoggedIn={isLoggedIn}/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/user_profile" element={<UserProfile />} />
          </Routes>
        </main>
        <Footer /> 
      </div>
    </BrowserRouter>
  );
}

export default App;