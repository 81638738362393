import React, { useState } from "react";
import { auth } from "../firebase"; // import the Firebase auth object
import firebase from "firebase/compat/app";
import { db } from "../firebase";
import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import withAuthentication from "../withAuthentication";
import CreatableSelect from 'react-select/creatable';


// Defininf the default values of the aimharder email, password and advance dates
const default_email_aimharder_value = ""
const default_password_value = ""
const default_advanceDays = "5"

function signOut() {
  auth.signOut();
  window.location.replace("/");
}

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}


function UserProfile() {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  let [email_aimharder_value, set_email_aimharder] = useState(null);
  let [email_aimharder_password, set_password_aimharder] = useState(null);
  let [advanceDays, set_advanceDays] = useState(default_advanceDays);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [popup, setPopup] = useState({ show: false, message: "", isError: false });
  const [isLoading, setIsLoading] = useState(true);
  const [userFinishTimes, setUserFinishTimes] = useState([]);
  const [reservationType, setReservationType] = useState("activityHour"); // Initialize reservationType with "activityHour" as the default value
  const [fixedHour, setFixedHour] = useState("10:00");

  // Used to hide the password when it's not needed
  const [showPassword, setShowPassword] = useState(false);
  const [eyeIcon, setEyeIcon] = useState('fa-eye-slash');  // Crossed eye by default

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setEyeIcon(eyeIcon === 'fa-eye' ? 'fa-eye-slash' : 'fa-eye');
  };

  const getUniqueActivities = (activities) => {
    return [...new Set([...activities, ...userActivities.map(activity => activity.activityType)])];
  }

  const Popup = ({ message, isError, onClose }) => (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50 transition-opacity ${
        isError ? "bg-red-200" : "bg-green-200"
      } bg-opacity-75`}
    >
      <div
        className={`bg-gray-200 p-8 rounded-lg shadow-xl w-11/12 md:w-1/2 ${
          isError ? "border-red-500" : "border-green-500"
        } border-2 flex flex-col items-center justify-center`}
      >
        <p className="text-xl text-center">{message}</p>
        <button
          className="mt-4 py-2 px-4 text-white rounded-lg shadow-md bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-75"
          onClick={onClose}
        >
          Cerrar
        </button>
      </div>
    </div>
  );  
  async function queryDocuments() {
    const user = firebase.auth().currentUser;

    if (user !== null) {
      const userId = user.uid.toString();

      var docRef = db.collection("users").doc(userId);

      let output = "empty";
      return docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            output = doc.data();

            return output;
          } else {
            // doc.data() will be undefined in this case

            return output;
          }
        })
        .catch((error) => {
          return output;
        });
    }
  }

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        handleRead().then(() => setIsLoading(false)); // Hide spinner after the data is loaded
      } else {
        setIsLoading(false); // Hide spinner if user is not logged in
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleRead = async () => {
    const user = firebase.auth().currentUser;
    
    if (user) {
      setPasswordHidden(true);
      const userId = user.uid.toString();
    
      const docRef = db.collection("users").doc(userId);
      const doc = await docRef.get();
      const userData = doc.data();
    
      if (userData) {
        set_email_aimharder(userData.email_aimharder || "");
        set_password_aimharder(userData.pass_aimharder || "");
        set_advanceDays(userData.advanceDays || "");
        setFixedHour(userData.fixHour || "10:00");
        setReservationType(userData.reservationType || "activityHour");
        if (userData.activities) {
          const activityData = userData.activities.map((activity) => {
            const { startTime, duration, activityType, dayOfWeek } = activity;
            // Calculate finish time here based on start time and duration
            // Convert start time (like "07:30") to its components (hours and minutes)
            const [startHours, startMinutes] = activity.startTime.split(":").map(Number);

            // Create a new Date object. You can use any date; the important part is the time.
            const startDate = new Date(2021, 0, 1, startHours, startMinutes);

            // Add the duration to the date object
            startDate.setMinutes(startDate.getMinutes() + Number(activity.duration));

            // Extract the new hours and minutes, ensuring they are two-digits
            const finishHours = String(startDate.getHours()).padStart(2, '0');
            const finishMinutes = String(startDate.getMinutes()).padStart(2, '0');

            // Combine them back into a time string
            let finishTime = `${finishHours}:${finishMinutes}`;
            return { startTime, finishTime, activityType, dayOfWeek };
          });
          setUserActivities(activityData);
          setActivities(Array.from({ length: activityData.length }, (_, i) => i));
          setUserFinishTimes(activityData.map(a => a.finishTime));
        }
      }
    }
  };

  const [activities, setActivities] = useState([0]);
  const [activityIndices, setActivityIndices] = useState([0]);
  const [userActivities, setUserActivities] = useState([]);

  const [firebaseActivities, setFirebaseActivities] = useState([]);
  const formattedFirebaseActivities = firebaseActivities.map(activity => ({ value: activity, label: activity }));

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const docRef = db.collection("publicData").doc("activities");
        const doc = await docRef.get();
        if (doc.exists) {
          const data = doc.data();
          // Assuming each field in the document is an activity
          const activities = Object.values(data);
          const combinedActivities = getUniqueActivities(activities);  // Pass the activities to your function
          setFirebaseActivities(combinedActivities);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching activities: ", error);
      }
    };
    
    fetchActivities();
  }, [userActivities]);  // Dependency array


  const validateActivities = () => {
    console.log("Validating activities...");
  
    for (let activity of userActivities) {
      console.log(`Activity: ${JSON.stringify(activity)}`); // Log the actual activity object
      if (!activity.dayOfWeek) {
        console.log("Validation failed: dayOfWeek is missing"); // Debugging
        return "Por favor, selecciona un día de la semana para todas las actividades.";
      }
      if (!activity.activityType) {
        console.log("Validation failed: activityType is missing"); // Debugging
        return "Por favor, selecciona o escribe un tipo de actividad para todas las actividades.";
      }
    }
    if (!reservationType) {
      console.log("Validation failed: reservationType is missing"); // Debugging
      return "Por favor, selecciona cuando permite empezar a reservar clases tu gimnasio";
    }
    if (reservationType=="fixHour" & !fixedHour) {
      console.log("Validation failed: fixedHour is missing"); // Debugging
      return "Por favor, selecciona a que hora permite empezar a hacer reservas tu gimansio";
    }
    console.log("All fields valid"); // Debugging
    return ""; // No error
  };
  
  

  const addActivity = () => {
    // Create a new activity with default invalid values
  const newActivity = {
    startTime: "07:00",
    finishTime: "08:00",
    activityType: "", // Default to an empty string, which is invalid
    dayOfWeek: "", // Default to an empty string, which is invalid
    duration: 60,
  };
  
  // Add the new activity to the userActivities state
  setUserActivities([...userActivities, newActivity]);
  setActivities([...activities, activities.length]);
  };
  const [inputValues, setInputValues] = useState([]);

  const handleDeleteActivity = (indexToDelete) => {
    const newActivities = userActivities.filter((_, index) => index !== indexToDelete);
    const newActivityIndices = activityIndices.filter((_, index) => index !== indexToDelete);
    const newActivitiesState = activities.filter((_, index) => index !== indexToDelete);
  
    setUserActivities(newActivities);
    setActivityIndices(newActivityIndices);
    setActivities(newActivitiesState);
  };
  
  const handleMenuClose = (index) => {
    const inputValue = inputValues[index];
    if (inputValue && inputValue.trim() !== "") {
        handleCreateOption(inputValue, index);
    }
  };


  const handleActivityChange = (index, field, value, label) => {
    setUserActivities(prevActivities => {
        const newActivities = [...prevActivities];
        if (!newActivities[index]) {
            newActivities[index] = {
                startTime: "07:00",
                duration: 60,  // default to 60 instead of "60"
                activityType: label || "",
                dayOfWeek: "",
                finishTime: "08:00"
            };
        }
        if (field === "finishTime") {
          newActivities[index]["finishTime"] = value;
          // Recalculate duration based on start and finish times
          // Convert start and finish times to their components (hours and minutes)
          const [startHours, startMinutes] = newActivities[index].startTime.split(":").map(Number);
          const [finishHours, finishMinutes] = newActivities[index].finishTime.split(":").map(Number);
        
          // Create Date objects for both start and finish times. The date part doesn't matter.
          const startDate = new Date(2021, 0, 1, startHours, startMinutes);
          const finishDate = new Date(2021, 0, 1, finishHours, finishMinutes);
        
          // Calculate the difference in minutes
          let duration = (finishDate - startDate) / (1000 * 60);
          newActivities[index]["duration"] = duration;
        }
        newActivities[index][field] = value;
        return newActivities;
    });
    setUserFinishTimes(prevFinishTimes => {
      const newFinishTimes = [...prevFinishTimes];
      if (field === "finishTime") {
        newFinishTimes[index] = value;
      }
      return newFinishTimes;
    });
    setInputValues(prevInputValues => {
        const newInputValues = [...prevInputValues];
        newInputValues[index] = label;
        return newInputValues;
    });
};

  
  const handleCreateOption = (newValue, index) => {
    handleActivityChange(index, 'activityType', newValue, newValue);
  };
  
  // Call this function when either 'Misma hora que las clases' or 'Hora fija' button is clicked
  const handleReservationTypeChange = (type) => {
    setPopup({ show: false, message: "", isError: false }); // Reset any previous messages
    setReservationType(type); // Update the reservation type state
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation and get the error message if any
    const errorMessage = validateActivities();
    
    // Debugging: Check the error message
    console.log("Error Message from validation:", errorMessage);

    // If there is an error message, update the popup and return early
    if (errorMessage) {
      console.log("Setting error popup"); // Debugging
      setPopup({ show: true, message: errorMessage, isError: true });
      return;
    } else {

    // Debugging: If we reach this point, validation has passed
    console.log("Proceeding with Firebase set operation...");

    // Recalculate the duration for all activities
    const updatedUserActivities = userActivities.map((activity) => {
      const [startHours, startMinutes] = activity.startTime.split(":").map(Number);
      const [finishHours, finishMinutes] = activity.finishTime.split(":").map(Number);
    
      const startDate = new Date(2021, 0, 1, startHours, startMinutes);
      const finishDate = new Date(2021, 0, 1, finishHours, finishMinutes);
    
      let duration = (finishDate - startDate) / (1000 * 60);
      return { ...activity, duration };
    });

    const user = firebase.auth().currentUser;
    const userId = user.uid.toString();

    db.collection("users")
      .doc(userId)
      .set({
        userId: auth.currentUser.uid,
        email_aimharder: email_aimharder_value || default_email_aimharder_value,
        pass_aimharder: email_aimharder_password || default_password_value,
        advanceDays: advanceDays || default_advanceDays,
        activities: updatedUserActivities,  // Save the updated activities
        reservationType,
        fixHour: reservationType === "fixHour" ? fixedHour : null,
      })
      .then(() => {
        setPopup({ show: true, message: "Datos guardados con éxito. El bot reservará tus classes justo cuando las siguientes esten disponibles.", isError: false });
      })
      .catch((error) => {
        setPopup({ show: true, message: "Ha ocurrido un error al guardar los datos. Por favor, inténtalo de nuevo.", isError: true });
        console.error("Error adding document: ", error);
      });
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#3b82f6" loading={isLoading} size={50} />
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit} className="bg-gray-200 p-6 rounded-lg shadow-md ">
      
      {popup.show && (
      <Popup 
        message={popup.message}
        isError={popup.isError}
        onClose={() => setPopup({ show: false, message: "", isError: false })}
      />
    )}
            <div className="m-3">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"/>
        <p className="text-center mt-3 mb-5">
        El bot entrará en AimHarder como si fuese tú cada día para reservar tus clase. Por esto, necesita tu nombre de usuario y contraseña de AimHarder.
        </p>
      </div>
      <div className="flex justify-center bg-gray-100 shadow-md m-2 max-w-md mx-auto my-8 border p-4 rounded-md">
        <div className="inline-block m-3 w-full">
          <label className="block font-medium mr-2">AimHarder Email:</label>
          <input
            id="input-aimharder-email"
            type="text"
            value={email_aimharder_value || ''}
            onChange={(e) => set_email_aimharder(e.target.value)}
            className="form-input rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500 text-center"
          />
        </div>
        <div className="inline-block m-3 w-full">
          <label className="block font-medium mr-2">
            AimHarder Contraseña:
          </label>
          <div className="flex border-2 border-black-400 rounded-md">
            <input
              id="input-aimharder-password"
              type={showPassword ? 'text' : 'password'}
              value={email_aimharder_password || ''}
              onChange={(e) => set_password_aimharder(e.target.value)}
              className="form-input rounded-l-md mt-1 block w-full focus:border-black-500 text-center"
            />
            <span className="input-group-text px-4 bg-gray-300 rounded-r-md">
              <i className={`fa ${eyeIcon} cursor-pointer`} onClick={togglePasswordVisibility}></i>
            </span>
          </div>
        </div>
      </div >
      <div className="m-3">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"/>
        <p className="text-center mt-3 mb-5">
        Selecciona si tu gimnasio abre las reservas en la misma hora que las classes o si las reservas se abren en una hora fija (10:00 por ejemplo)
        </p>
      </div>
      <div className="flex justify-center bg-gray-100 shadow-md m-2 max-w-md mx-auto my-8 border p-4 rounded-md">
        <button
          type="button" // Ensure this is set to "button" to prevent form submission
          className={`px-4 py-2 mx-2 ${reservationType === "activityHour" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => handleReservationTypeChange("activityHour")} // Update to the correct reservation type
        >
          Misma hora que la actividad
        </button>
        <button
          type="button" // Ensure this is set to "button" to prevent form submission
          className={`px-4 py-2 mx-2 ${reservationType === "fixHour" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => handleReservationTypeChange("fixHour")} // Update to the correct reservation type
        >
          Hora fija (10:00 por ejemplo)
        </button>
      </div>

      <div className="flex justify-center bg-gray-100 shadow-md m-2 max-w-md mx-auto my-8 border p-4 rounded-md">
        <div className="inline-block m-3">
          {reservationType === "activityHour" && (
            <div className="inline-block m-3">
              <label htmlFor="antelacion" className="block font-medium mr-2">
                Reservar con X días de antelación:
              </label>
              <label htmlFor="antelacion" className="block font-small mr-2">
                Depende del gimnasio, por ejemplo, si permite reservar el lunes para el sábado son 5 días de antelación
              </label>
              <select
                id={`antelacion`}
                name="antelacion"
                type="text"
                className="form-input rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500 text-center"
                value={advanceDays || 5}
                onChange={(e) => set_advanceDays(e.target.value)}
              >
                <option value="1">1 día</option>
                <option value="2">2 días</option>
                <option value="3">3 días</option>
                <option value="4">4 días</option>
                <option value="5">5 días</option>
                <option value="6">6 días</option>
                <option value="7">7 días</option>
              </select>
            </div>
          )}

  {reservationType === "fixHour" && (
    <div className="inline-block m-3">
      <label htmlFor="fixedHour" className="block font-medium mr-2">
        Hora fija que abren las reservas:
      </label>
      <input
        id="fixedHour"
        type="time"
        className="form-input rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500 text-center"
        value={fixedHour}
        onChange={(e) => setFixedHour(e.target.value)}
      />
      <div className="inline-block m-3">
        <label htmlFor="antelacion" className="block font-medium mr-2">
          Reservar con X días de antelación:
        </label>
        <label htmlFor="antelacion" className="block font-small mr-2">
          Depende del gimnasio, por ejemplo, si permite reservar el lunes para el sábado son 5 días de antelación
        </label>
        <select
          id={`antelacion`}
          name="antelacion"
          type="text"
          className="form-input rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500 text-center"
          value={advanceDays || 5}
          onChange={(e) => set_advanceDays(e.target.value)}
        >
          <option value="1">1 día</option>
          <option value="2">2 días</option>
          <option value="3">3 días</option>
          <option value="4">4 días</option>
          <option value="5">5 días</option>
          <option value="6">6 días</option>
          <option value="7">7 días</option>
        </select>
      </div>
    </div>
  )}

        </div>
      </div>
      <div className="m-3">


        <h1 className="text-3xl font-bold text-center mb-4">
          Configura tus sesiones
        </h1>
        <p className="text-center">
          Selecciona todas las clases que quieres que el bot te seleccione.
        </p>
        <p className="text-center mb-4">
          Es importante que todos los detalles sean exactos para que el bot
          funcione correctamente.
        </p>
      </div>
      <div className="max-w-md mx-auto my-8 border p-4 rounded-md">
        {activities.map((activity, index) => (
          <div key={index} className="activity bg-gray-100 shadow-md rounded-md p-4 m-2">
            <div>
            <div key={index} className="relative mb-8 p-4">
  <button
    type="button"
    onClick={() => handleDeleteActivity(index)}
    className="absolute top-0 right-0 mt-1 mr-1 py-1 px-2 text-white bg-red-500 rounded-md shadow-md focus:outline-none hover:bg-red-600"
    title="Haz clic para eliminar esta actividad"
  >
    X
  </button>
          </div>
            <div className="mb-8">
              <label className="block font-medium mt-5">
                Horario de inicio y de fin de la actividad:
              </label>
              <div className="flex">
                <div className="w-1/2 mr-4">
                <label className="block font-small mt-5">
                Inicio de la actividad
                </label>
                <input
                  type="time"
                  className="form-input text-center rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500"
                  onChange={(e) => handleActivityChange(index, "startTime", e.target.value)}
                  value={userActivities[index]?.startTime || "07:00"}
                  onFocus={(e) => e.target.click()}
                />


                </div>
                <div className="w-1/2">
                <label className="block font-small mt-5">
                Fin de la actividad
                </label>
                <input
                  type="time"
                  className="form-input text-center rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500"
                  onChange={(e) => handleActivityChange(index, "finishTime", e.target.value)}
                  value={userFinishTimes[index] || "08:00"}
                  onFocus={(e) => e.target.click()}
                />
              </div>
              </div>
              <div className="mb-4">
              <div className="flex items-center justify-center mt-5">
                <label className="block font-medium" htmlFor="activity-type-1">
                    Tipo de actividad
                </label>
              </div>
              <div className="">
                <p>El tipo de actividad seleccionado tiene que ser exactamente el mismo que aparece en tu AimHarder. Si no está en la lista puedes escribirlo en lugar de seleccionarlo.</p>
                <img src="/activity_type_example.png" alt="EjemploTipoActividad" className="mt-2 w-full" />
              </div>

                <CreatableSelect
                  key={`${index}-${userActivities[index]?.activityType}`}
                  isClearable
                  onChange={(inputValue, actionMeta) => {
                    handleActivityChange(index, 'activityType', inputValue ? inputValue.value : '', inputValue ? inputValue.label : '')
                  }}
                  onMenuClose={() => handleMenuClose(index)}
                  options={formattedFirebaseActivities}
                  placeholder="Selecciona o escribe tu actividad"
                  formatCreateLabel={(inputValue) => `Crear "${inputValue}"`}
                  onInputChange={(inputValue) => {
                    setInputValues(prevInputValues => {
                      const newInputValues = [...prevInputValues];
                      newInputValues[index] = inputValue;
                      return newInputValues;
                    });
                  }}
                  inputValue={inputValues[index] || ''}
                  onCreateOption={(newValue) => handleCreateOption(newValue, index)}  // ensure this is working as expected
                  value={userActivities[index]?.activityType ? { value: userActivities[index]?.activityType, label: userActivities[index]?.activityType } : null}
              />
              </div>
              <div className="flex items-center justify-center mt-5">
                <label
                  className="block w-full"
                  htmlFor="activity-day-1"
                >
                  Día de la semana
                </label>
                <select 
                  id={`activity-day-${index}`}
                  className="form-input text-center rounded-md mt-1 block w-full border-2 border-black-400 focus:border-black-500"
                  onChange={(e) =>
                    handleActivityChange(index, "dayOfWeek", e.target.value)
                  }
                  value={userActivities[index]?.dayOfWeek || ""}
                >
                  <option disabled value="">
                    Selecciona un día
                  </option>
                  <option value="0">Lunes</option>
                  <option value="1">Martes</option>
                  <option value="2">Miércoles</option>
                  <option value="3">Jueves</option>
                  <option value="4">Viernes</option>
                  <option value="5">Sábado</option>
                  <option value="6">Domingo</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      ))}
        <div className="flex justify-center">
          <button
            type="button"
            onClick={addActivity}
            className="mt-5 py-2 px-4 bg-slate-500 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Añadir otra actividad
          </button>
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <button
          type="button"
          onClick={signOut}
          className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 m-2"
        >
          Desconectar
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 m-2"
        >
          Guardar
        </button>
      </div>
    </form>
  );
}

export default withAuthentication(UserProfile);