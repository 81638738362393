import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import styles from "./index.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import './firebase'

function Index() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setIsLoggedIn(true);
        var uid = user.uid;
        // ...
      } else {
        // User is signed out
        setIsLoggedIn(false);
        // ...
      }
      setIsLoading(false);
    });
  }, []);


  return (
    <React.StrictMode>
      <App isLoggedIn={isLoggedIn} isLoading={isLoading} />
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
