import React from 'react';

const BuyMeCoffeeButton = () => {
  return (
    <div style={{ 
      margin: '20px',
      display: 'inline-block'
    }}>
      <a 
        href="https://buymeacoffee.com/aimharderbot?l=es" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{
          backgroundColor: '#3b82f6',
          color: 'white',
          padding: '7px 15px',
          border: 'none',
          borderRadius: '5px',
          fontFamily: 'Cookie, cursive',
          fontSize: '25px',
          textDecoration: 'none',
          display: 'inline-flex',
          alignItems: 'center',
          transition: 'all 0.3s ease',
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3b82f6'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#3b82f6'}
      >
        <svg 
          style={{ marginRight: '5px' }} 
          width="20" 
          height="20" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M18 8h1a4 4 0 0 1 0 8h-1" />
          <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />
          <line x1="6" y1="1" x2="6" y2="4" />
          <line x1="10" y1="1" x2="10" y2="4" />
          <line x1="14" y1="1" x2="14" y2="4" />
        </svg>
        Ayuda a mantener AimHarderBot
      </a>
    </div>
  );
};

export default BuyMeCoffeeButton;