import React, { useEffect, useState } from "react"
import {auth} from "../firebase"

function NavBar({ isLoggedIn, isLoading }) {

// In order to load the profile picture or initials
const [photoURL, setPhotoURL] = useState(null);
const [initials, setInitials] = useState(null);

useEffect(() => {
const unsubscribe = auth.onAuthStateChanged(user => {
if (user) {
if (user.photoURL) {
setPhotoURL(user.photoURL);
} else {
const name = user.displayName.split(" ");
const initial = name[0][0] + name[1][0];
setInitials(initial);
}
} else {
setPhotoURL(null);
setInitials(null);
}
});
return () => unsubscribe();
}, []);

return (
<nav className="bg-gray-800 py-4 shadow-md">
<div className="container mx-auto px-6">
<div className="flex items-center justify-between">
<a href="/" className="text-lg font-bold text-gray-100 no-underline">
<img src="/aimharderlogobot.png" alt="Logo" className="h-10" />
</a>
<a href="/" className="text-lg font-bold text-gray-100 no-underline">
AimHarder Bot
</a>
<div className="flex items-center">
<a href="/" className="px-3 py-2 rounded-full text-gray-100 hover:bg-gray-600">
Home
</a>
{!isLoading && isLoggedIn &&(
    <a id="UserProfile" href="/user_profile" className="relative rounded-full overflow-hidden w-8 h-8">
    {photoURL ? (
    <img src={photoURL} alt="User" className="object-cover rounded-full w-full h-full" />
    ) : (
    <div className="text-white text-center bg-gray-900 rounded-full w-full h-full flex items-center justify-center">
    {initials}
    </div>
    )}
    </a>
)}
{!isLoading && !isLoggedIn &&(
    <a href="/login" id="Login" className="px-3 py-2 rounded-full text-gray-100 hover:bg-gray-600" >
Login
</a>
)}

</div>
</div>
</div>
</nav>
);
}

export default NavBar;