import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase"; // Make sure this import points to your firebase.js file
import firebase from 'firebase/compat/app';

function withAuthentication(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      // Set persistence to local to keep user signed in even if page is refreshed
      auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        // Listener to check for auth state changes
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            navigate("/user_profile");
          } else {
            navigate("/login");
          }
        });

        return () => {
          unsubscribe();
        };
      });
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
}

export default withAuthentication;
