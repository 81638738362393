import { initializeApp } from "firebase/app";
import {GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/compat/auth";
import { getFirestore,query,getDocs,collection,where,addDoc} from "firebase/firestore";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// Firebase documentation: https://firebase.google.com/docs/auth/web/firebaseui



const firebaseConfig = {
    apiKey: "AIzaSyA24vI9VIZOp_QnAY8m2xD1g-YXOWT16Yk",
    authDomain: "glogic-373218.firebaseapp.com",
    projectId: "glogic-373218",
    storageBucket: "glogic-373218.appspot.com",
    messagingSenderId: "636966837653",
    appId: "1:636966837653:web:2180ac5c087de92ae62a9c",
    measurementId: "G-397PXDK3DW",
    databaseURL: 'https://glogic-373218-default-rtdb.europe-west1.firebasedatabase.app/',
  };

  var uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            console.log('signInSuccessWithAuthResult', authResult);
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true;
        },
        uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader').style.display = 'none';
        },
        signInFailure: function(error) {
            // For merge conflicts, the error.code will be
            // 'firebaseui/anonymous-upgrade-merge-conflict'.
            if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
                return Promise.resolve();
            }
            // The credential the user tried to sign in with.
            var cred = error.credential;
            // Copy data from anonymous user to permanent user and delete anonymous
            // user.
            // ...
            // Finish sign-in after data is copied.
            return firebase.auth().signInWithCredential(cred);
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    autoUpgradeAnonymousUsers: true,
    signInSuccessUrl: '/user_profile',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
    // Privacy policy url.
    privacyPolicyUrl: '<your-privacy-policy-url>'
};





// Initialize Firebase
const app = initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = firebase.auth();



// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());

// Is there an email link sign-in?
/*
if (ui.isPendingRedirect()) {
    ui.start('#firebaseui-auth-container', uiConfig);
  }
  */
// This can also be done via:
/*
if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
   
ui.start('#firebaseui-auth-container', uiConfig);
}
*/
// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();



  
// The start method will wait until the DOM is loaded.
function InitializeFireBase() {
    // Temp variable to hold the anonymous user data if needed.
    var data = null;
    // Hold a reference to the anonymous current user.
    var anonymousUser = firebase.auth().currentUser;
    
    if (window.location.href.indexOf('signInSuccessUrl') !== -1) {
      window.location.href = '/user_profile';
    }
    
    
    ui.start('#firebaseui-auth-container', uiConfig);
    
    return (
        <div>
            <h1> </h1>
        </div>
    );
  }
  
  export default InitializeFireBase;
